import React from "react";

// Components
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Link } from "gatsby";

// Styles
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

// Types
import { StandardProps } from "@material-ui/core";

export type CardClassKey =
  | "root"
  | "illustration"
  | "content"
  | "title"
  | "description"
  | "button";
const styles = (theme: Theme) =>
  createStyles({
    root: ({ discoverLink }: CardProps) => ({
      minHeight: discoverLink ? 450 : 400,

      padding: "48px 24px 24px 24px",
      display: "flex",
      flexFlow: "column",

      // Card
      background: theme.palette.background.paper,
      boxShadow: "0px 15px 50px rgba(28,15,125,0.05)",
      borderRadius: "6px",
    }),
    illustration: {
      textAlign: "center",
      marginBottom: theme.spacing(4),
    },
    content: {
      display: "flex",
      flexGrow: 1,
      flexFlow: "column",
    },
    title: {
      fontWeight: 500,
      color: "#262a3e",
    },
    description: {},
    button: {
      marginTop: theme.spacing(4),
      borderRadius: 0,
    },
  });

export type CardProps = StandardProps<
  {
    /** Small illustration at the top of the card */
    illustration: React.ReactElement;

    /** Title of card */
    title: React.ReactElement | string;

    /** Description of the card */
    description: React.ReactElement | string;

    /**
     * When given, a "Découvrir" button will be rendered, redirected to the given
     * value.
     *
     * If not given, no button will be rendered
     *
     * @default undefined
     */
    discoverLink?: string;
  },
  CardClassKey
>;

const Card = ({
  illustration,
  title,
  description,
  discoverLink,
  classes,
}: CardProps): JSX.Element => {
  return (
    <div className={classes?.root}>
      <div className={classes?.illustration}>{illustration}</div>
      <div className={classes?.content}>
        <Typography
          className={classes?.title}
          variant="h6"
          align="center"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          className={classes?.description}
          variant="body1"
          align="center"
        >
          {description}
        </Typography>
      </div>
      {discoverLink ? (
        <Button
          component={Link}
          className={classes?.button}
          variant="outlined"
          color="primary"
          size="large"
          to={discoverLink}
        >
          Découvrir
        </Button>
      ) : null}
    </div>
  );
};

export default withStyles(styles)(Card);
