import React from "react";

// Components
import Grid from "@material-ui/core/Grid";
import Panel, { PanelProps } from "../Panel";

export const EvolutiveSolution = (props: Partial<PanelProps>): JSX.Element => {
  return (
    <Panel
      hideButton
      imageRight
      panel={{
        id: "1",
        imageFilename: "api.jpeg",
        title: "Une solution évolutive",
        subtitle: "Augmentez votre efficacité en permanence",
        body: (
          <>
            <p>
              <b>
                Déployez très simplement eKee auprès de vos salariés, clients,
                fournisseurs, adhérents, administrés, ...
              </b>
              <br />
              Chaque nouveau membre eKee est source de plus d'efficacité, de
              gains de temps et d'économies.
            </p>
            <br />
            <p>
              <b>Connectez eKee à votre SI quand vous le souhaitez</b>
              <br />
              eKee met à disposition une API performante qui augmentera la
              fiabilité de votre Système d'Information.
            </p>
            <br />
            <p>
              <b>Optimisez l'exploitation de votre SI avec l'Engine eKee</b>
              <br />
              Exploitez la puissance de l'Engine eKee pour générer vos propres
              API, des calback, des webhooks ou encore tout type d'actions
              d'automatisation. Contactez-nous.
            </p>
          </>
        ),
      }}
      {...props}
    />
  );
};

export default EvolutiveSolution;
