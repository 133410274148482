import React from "react";

// Components
import Card from "../Card";
import Grid, { GridProps } from "@material-ui/core/Grid";
import Image from "../image";

// Types
import { CSSProperties } from "@material-ui/styles";

const imageStyles: CSSProperties = {
  height: 64,
  maxWidth: 64,
  margin: "auto",
} as const;

const problems = [
  {
    id: 1,
    imageFilename: "efficiency-icon.png",
    title: "Gagnez en efficacité",
    description:
      "Assurez la mise à jour permanente de vos bases de tiers (clients, fournisseurs, ...) et des contacts associés ainsi que des données personnelles de vos salariés.",
    discoverLink: "/blog/gestion-de-contacts",
    imageStyles,
  },
  {
    id: 2,
    imageFilename: "refresh.png",
    title: "Une gestion simplifiée",
    description:
      "eKee centralise immédiatement et automatise tous vos besoins de collecte et diffusion de vos données et documents.",
    discoverLink: "/blog/gestion-de-documents",
    imageStyles: {
      ...imageStyles,
      width: 52,
    },
  },
  {
    id: 3,
    imageFilename: "control-icon.png",
    title: "Gardez le contrôle",
    discoverLink: "/blog/gestion-de-consentements",
    description:
      "Avec les consentements eKee, maîtrisez vos sources de données et contrôlez parfaitement la diffusion de vos données et documents.",
    imageStyles,
  },
] as const;

const Problematics = (props: GridProps): JSX.Element => {
  return (
    <Grid container spacing={3} {...props}>
      {problems.map((p) => (
        <Grid item xs={12} sm={4} key={p.id}>
          <Card
            title={p.title}
            description={p.description}
            discoverLink={p.discoverLink}
            illustration={
              <Image style={p.imageStyles} filename={p.imageFilename} />
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Problematics;
