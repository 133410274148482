import React from "react";

// Components
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// Icons
import CheckIcon from "@material-ui/icons/Check";

// Styles
import clsx from "clsx";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const points = [
  "Gardez vos bases de tiers et leurs contacts associés fiables et à jour en permanence",
  "Ne vous souciez plus de l'actualisation des données personnelles de vos salariés",
  "Collectez et partagez vos données et documents en toute simplicité",
  "Disposez d'une veille juridique permanente sur vos clients et fournisseurs",
] as const;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  list: {
    marginTop: theme.spacing(4),
  },
}));

export type IntroductionProps = React.HTMLProps<HTMLElement>;

const Introduction = (props: IntroductionProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section
      style={{ display: "flex" }}
      {...props}
      className={clsx(props.className, classes.root)}
    >
      <Typography variant="h4" align="center">
        La transformation numerique de votre entreprise accessible immediatement
      </Typography>
      <List dense className={classes.list}>
        {points.map((p, idx) => (
          <ListItem key={`enterprise-point-${idx}`}>
            <Point>{p}</Point>
          </ListItem>
        ))}
      </List>
    </section>
  );
};

const Point = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",

    "& .MuiSvgIcon-root": {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
}))(({ children, ...props }: TypographyProps) => (
  <Typography {...props}>
    <CheckIcon fontSize="small" />
    {children}
  </Typography>
));

export default Introduction;
