import React from "react";

// Components
import Container from "@material-ui/core/Container";
import SEO from "../components/SEO";
import Problematics from "../components/EnterprisePage/Problematics";
import Image from "../components/image";
import Services from "../components/Services";
import Introduction from "../components/EnterprisePage/Introduction";
import Typography from "@material-ui/core/Typography";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import EvolutiveSolution from "../components/EnterprisePage/EvolutiveSolution";
import ConvexSvg from "../components/ConvexSvg";

const styles = makeStyles((theme) => ({
  section1: {
    paddingBottom: theme.spacing(12),
    background: theme.gradients.gradientWhiteGrey,
  },
  intro: {
    marginTop: theme.spacing(6),
  },
  problematics: {
    marginTop: theme.spacing(0.5),
  },
  section2: {},
  image: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(4),
    width: 240,
    margin: "auto",
  },
  services: {
    marginBottom: theme.spacing(8),
  },
  section3: {
    background: theme.gradients.gradientGreyWhite,
  },
  api: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(12),
  },
}));

const IndexPage = (): JSX.Element => {
  const classes = styles();

  return (
    <>
      <SEO title="eKee" />
      <article className={classes.section1}>
        <Container maxWidth="md" component="main">
          <Introduction className={classes.intro} />
          <Problematics className={classes.problematics} />
        </Container>
      </article>
      <section className={classes.section2}>
        <ConvexSvg variant="top" color="white" />
        <Container maxWidth="md" component="main">
          <Image filename="ekee-complete-logo.png" className={classes.image} />
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            style={{ marginBottom: 24 }}
          >
            Une solution simple et autonome pour des bénéfices immédiats
          </Typography>
          <Services className={classes.services} />
        </Container>
      </section>
      <section className={classes.section3}>
        <ConvexSvg variant="bottom" color="white" />
        <Container maxWidth="lg">
          <EvolutiveSolution className={classes.api} />
        </Container>
      </section>
    </>
  );
};

export default IndexPage;
